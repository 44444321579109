import { Spinner as PolarisSpinner, SpinnerProps } from "@shopify/polaris";
import React from "react";
import styled from "styled-components";

const SpinnerContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 4rem 0;

  svg {
    fill: #2b2b70 !important;
  }
`;

export function Spinner(props: SpinnerProps): JSX.Element {
  return (
    <SpinnerContainer>
      <PolarisSpinner {...props} size={props.size || "small"} />
    </SpinnerContainer>
  );
}
